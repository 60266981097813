





















import { Component, Mixins } from 'vue-property-decorator'
import { ModalFunctions } from '~/mixins/modals'
import { fetchCoupon } from '@/api/coupon'
import { CartStore } from '~/store/cart'

@Component
export default class Coupon extends Mixins(ModalFunctions) {
	couponToken: string = ''
	couponResponse: string = ''

	@CartStore.Mutation('ADD_COUPON') ADD_COUPON: (coupon: ICouponArtist) => void

	async applyCoupon() {
		const coupon = await fetchCoupon(this.couponToken)
		// if the coupon exists and has remaining usages
		const couponIsValid = coupon && (coupon.usage_available_is_infinite || coupon.usage_available_left > 0)
		if (couponIsValid) {
			const producer = coupon.producer.display_name
			const discount = coupon.discount_percentage
			this.couponResponse = `Coupon is valid, ${discount}% off on ${producer}'s beats!`
			// add to store
			this.ADD_COUPON(coupon)
			setTimeout(() => {
				// reset input & close modal
				this.couponToken = ''
				this.closeModal()
			}, 2000)
		} else {
			this.couponResponse = 'Sorry, this coupon is not valid. Contact the producer for more info.'
		}
	}

	validateFields() {
		this.applyCoupon()
	}
}
