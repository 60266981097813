import { get, post, remove } from '~/services/http-requests'

const _BS_COUPONS = 'coupons'

/*
 **** GET ****
 */
async function fetchCoupon(couponToken: string): Promise<ICouponArtist> {
    const [first] = await get({ baseProperty: _BS_COUPONS, fullUrl: `${_BS_COUPONS}.json?token=${couponToken}` })
    return first
}

export { fetchCoupon }
